// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
@import "variables";

// Bootstrap
@import "~bootstrap-sass/assets/stylesheets/bootstrap";
//@import url('https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css');

// Font Awesome
@import "~font-awesome/scss/font-awesome";
//@import '/node_modules/font-awesome/css/font-awesome.css';

// Helpers
@import "../helpers";

// Global
@import "global";

// Pages
@import "pages/dashboard";

// Plugins
@import "../plugin/toastr/toastr.scss";
@import "../plugin/sweetalert/sweetalert.scss";